import { QueryCoder, QueryHandler, Type } from '@lfgroup/query-coder';

import * as api from '../../graphql/generated/index';
import { filterLfgByGame } from '../filterGame';

import { getGameSlugPath } from '../location';

import * as aliases from './aliases';
import {
  routeIndexCoder,
  queryGameId,
  queryMode,
  // queryTag,
  wowRegionsMap,
} from './pageIndex';

import type { UrlObject } from 'url';

export const SESSION_PLAYERS_QUERY_KEY = (gameId: api.Maybe<api.GameId>) =>
  `lfg/query-players/${gameId}`;

const handler = routeIndexCoder.handlers;

export const routePlayersCoder = new QueryCoder<api.SearchPlayersQueryVariables>({
  iteratorOptions: {
    offset: handler.iteratorOptions?.offset?.clone<api.SearchPlayersQueryVariables>(
      {} as any,
    ),
  },
  filter: {
    tags: new QueryHandler({ query: 'tags', decodeType: Type.Array }),
    rank: new QueryHandler({
      query: 'rank',
      decodeType: Type.Array,
    }),
    gameId: queryGameId.clone<api.SearchPlayersQueryVariables>({} as any),
    mode: queryMode.clone<api.SearchPlayersQueryVariables>({} as any),
    user: {
      battlenetRegion:
        handler.filter?.battlenetRegion?.clone<api.SearchPlayersQueryVariables>(
          {} as any,
        ),
      riotRegion: new QueryHandler({ query: 'riot', aliases: aliases.riotRegion }),
      username: new QueryHandler({ query: 'user_name' }),
    },
    lostArk: {
      class: new QueryHandler({
        query: 'class',
        aliases: aliases.laClass,
        decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
      }),
      region: new QueryHandler({
        query: 'region',
        aliases: aliases.laRegion,
        decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
      }),
      legacyLvl: new QueryHandler({
        query: 'legacy',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
      }),
      gearScore: new QueryHandler({
        query: 'gs',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
      }),
    },
    lol: {
      region: new QueryHandler({
        query: 'region',
        aliases: aliases.lolRegion,
        decodeCondition: { filter: { gameId: api.GameId.LeagueOfLegends } },
      }),
      flexRank: new QueryHandler({
        query: 'flexq',
        aliases: aliases.lolRank,
        decodeCondition: { filter: { gameId: api.GameId.LeagueOfLegends } },
      }),
      soloRank: new QueryHandler({
        query: 'soloq',
        aliases: aliases.lolRank,
        decodeCondition: { filter: { gameId: api.GameId.LeagueOfLegends } },
      }),
      role: new QueryHandler({
        query: 'role',
        aliases: aliases.lolRole as Record<api.SlotRole, string>,
        decodeCondition: { filter: { gameId: api.GameId.LeagueOfLegends } },
      }),
    },
    csgo: {
      faceitLvl: new QueryHandler({
        query: 'faceitLvl',
        decodeType: Type.Array,
        decodeCondition: { filter: { gameId: api.GameId.Csgo } },
      }),
    },
    wow: {
      faction: new QueryHandler({
        query: 'faction',
        aliases: aliases.wowFaction,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      race: new QueryHandler({
        query: 'race',
        aliases: aliases.wowRace,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      class: new QueryHandler({
        query: 'class',
        aliases: aliases.wowClass,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      specialization: new QueryHandler({
        query: 'specialization',
        aliases: aliases.wowSpec,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      level: new QueryHandler({
        query: 'level',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      ilvl: new QueryHandler({
        query: 'ilvl',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      ilvlPvp: new QueryHandler({
        query: 'ilvl_pvp',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      rioScore: new QueryHandler({
        query: 'm_plus',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      arenaRating2x2: new QueryHandler({
        query: '2x2',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      arenaRating3x3: new QueryHandler({
        query: '3x3',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      arenaRatingBattlegrounds: new QueryHandler({
        query: 'arena_bg',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      raidSepulcherNormalKilled: new QueryHandler({
        query: 'raid_sfo_normal',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      raidSepulcherHeroicKilled: new QueryHandler({
        query: 'raid_sfo_heroic',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      raidSepulcherMythicKilled: new QueryHandler({
        query: 'raid_sfo_mythic',
        decodeType: Type.Number,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
      wowRegion: new QueryHandler({
        query: 'region',
        aliases: aliases.wowRegion,
        decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
      }),
    },
  },
});

// Redirect from `?game=wow&mode=mythic-plus` to `/wow-mplus`
export function encodeVarsAndReplaceGameSlug(
  variables: api.SearchPlayersQueryVariables,
  pathname = '/players/',
  dropHistory = false,
): UrlObject {
  let query = routePlayersCoder.encode(variables);

  if (dropHistory) {
    query += 'drop_history=true';
  }

  if (variables.filter.gameId) {
    const slug = getGameSlugPath(variables.filter.gameId, variables.filter.mode);

    if (!slug.path) {
      return { pathname: pathname, search: query };
    }

    // query.delete(QUERY_KEY_GAME_SLUG);
    // if (slug.deleteModeQuery) {
    //   q.delete(QUERY_KEY_GAME_MODE);
    // }

    return { pathname: pathname + slug.path, search: query };
  }

  return { pathname, search: query };
}

const PLAYERS_LIMIT = 10;

export function getDefaultFilters(
  me: api.Maybe<api.User>,
  gameId?: api.GameId,
): api.SearchPlayersQueryVariables {
  const myRegion = me?.battlenet && wowRegionsMap[me.battlenet.region];

  const vars: api.SearchPlayersQueryVariables = {
    iteratorOptions: { limit: PLAYERS_LIMIT, offset: 0 },
    filter: {
      gameId,
      user: {
        ...(gameId === api.GameId.WorldOfWarcraft && {
          battlenetRegion: me?.battlenet?.region,
        }),
      },
    },
  };

  if (myRegion && gameId === api.GameId.WorldOfWarcraft) {
    vars.filter.wow = { wowRegion: myRegion };
  }

  if (me && gameId === api.GameId.LeagueOfLegends) {
    const profile = filterLfgByGame(api.GameId.LeagueOfLegends, me?.lfg);
    if (profile) {
      vars.filter.lol = {};

      if (profile.lol?.region) {
        vars.filter.lol.region = profile.lol.region;
      }
    }
  }
  if (me && gameId === api.GameId.LostArkEn) {
    const profile = filterLfgByGame(api.GameId.LostArkEn, me?.lfg);
    if (profile?.lostArk?.region?.length) {
      vars.filter.lostArk = { region: profile.lostArk.region[0] as any };
    }
  }

  return vars;
}
