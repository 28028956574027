import { QueryCoder, QueryHandler, Type } from '@lfgroup/query-coder';

import * as api from 'graphql/generated/index';
import { getGameSlugPath } from 'utils/location';
import * as aliases from 'utils/url/aliases';

import type { SupportedGameId } from 'api/types';

import type { UrlObject } from 'url';

export const QUERY_HANDLER_GAME_SLUG = new QueryHandler<
  SupportedGameId,
  api.SearchEventsQueryVariables
>({ query: 'game', aliases: aliases.gameId });

export const QUERY_HANDLER_GAME_MODE = new QueryHandler<
  api.GameMode,
  api.SearchEventsQueryVariables
>({ query: 'mode' });

export const GROUPS_FEED_QUERY_CODER = new QueryCoder<api.SearchEventsQueryVariables>(
  {
    iteratorOptions: {
      offset: new QueryHandler({
        query: 'offset',
        decodeType: Type.Number,
        encodable: false,
      }),
    },
    filter: {
      gameMode: QUERY_HANDLER_GAME_MODE,
      battlenetRegion: new QueryHandler({
        query: 'bnet',
        aliases: aliases.battlenetRegion,
      }),
      upcoming: new QueryHandler({
        query: 'upcoming',
        decodeType: Type.Boolean,
        acceptEmptyValue: true,
      } as any),
      language: new QueryHandler({ query: 'locale', aliases: aliases.language }),
      from: new QueryHandler({ query: 'from' }),
      type: new QueryHandler({ query: 'type' }),
      // ...(filters.from && { from: fromUnmarshal(filters.from) }),
      // ...(query.from && { from: fromMarshal(query.from as EventStartOption) }),
      region: new QueryHandler({ query: 'rgn' }),
      lostArk: {
        region: new QueryHandler({
          query: 'region',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        abyssDungeonsAbyss: new QueryHandler({
          query: 'abyss',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        abyssDungeonsDungeon: new QueryHandler({
          query: 'abyss_dungeon',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        abyssDungeonsDungeonGearScore: new QueryHandler({
          query: 'abyss_gs',
          decodeType: Type.Number,
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        guardianRaidsGuardian: new QueryHandler({
          query: 'guardian',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        bossRush: new QueryHandler({
          query: 'boss_rush',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        arena: new QueryHandler({
          query: 'arena',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        arenaMode: new QueryHandler({
          query: 'arena_mode',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        arenaServer: new QueryHandler({
          query: 'arena_server',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        cubeDungeon: new QueryHandler({
          query: 'cube',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        cubeDungeonGearScore: new QueryHandler({
          query: 'cube_gs',
          decodeType: Type.Number,
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        platinumFieldsField: new QueryHandler({
          query: 'pl_fields',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        otherActivity: new QueryHandler({
          query: 'other_act',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        difficulty: new QueryHandler({
          query: 'difficulty',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        raid: new QueryHandler({
          query: 'raid',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        phase: new QueryHandler({
          query: 'phase',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        server: new QueryHandler({
          query: 'server',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        card: new QueryHandler({
          query: 'card',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        gift: new QueryHandler({
          query: 'gift',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
        zone: new QueryHandler({
          query: 'zone',
          decodeCondition: { filter: { gameId: api.GameId.LostArkEn } },
        }),
      },
      lol: {
        region: new QueryHandler({
          query: 'region',
          aliases: aliases.lolRegion,
          decodeCondition: { filter: { gameId: api.GameId.LeagueOfLegends } },
        }),
        rank: new QueryHandler({
          query: 'rank',
          aliases: aliases.lolRank,
          decodeCondition: { filter: { gameId: api.GameId.LeagueOfLegends } },
        }),
      },
      csgo: {
        region: new QueryHandler({
          query: 'region',
          decodeCondition: { filter: { gameId: api.GameId.Csgo } },
        }),
        rank: new QueryHandler({
          query: 'rank',
          decodeCondition: { filter: { gameId: api.GameId.Csgo } },
        }),
      },
      warzone: {
        region: new QueryHandler({
          query: 'region',
          decodeCondition: { filter: { gameId: api.GameId.Warzone } },
        }),
      },
      hearthstone: {
        rating: new QueryHandler({
          query: 'bg_rating',
          decodeType: Type.Number,
          decodeCondition: { filter: { gameId: api.GameId.Hearthstone } },
        }),
        region: new QueryHandler({
          query: 'region',
          decodeCondition: { filter: { gameId: api.GameId.Hearthstone } },
        }),
      },
      wowBurningCrusade: {
        arenaRating: new QueryHandler({
          query: 'a_rating',
          decodeType: Type.Number,
          decodeCondition: {
            filter: { gameId: api.GameId.WorldOfWarcraftBurningCrusade },
          },
        }),
        heroic: new QueryHandler({
          query: 'heroic',
          decodeType: Type.Boolean,
          decodeCondition: {
            filter: { gameId: api.GameId.WorldOfWarcraftBurningCrusade },
          },
        } as any),
        faction: new QueryHandler({
          query: 'faction',
          aliases: aliases.wowFaction,
          decodeCondition: {
            filter: { gameId: api.GameId.WorldOfWarcraftBurningCrusade },
          },
        }),
        dungeon: new QueryHandler({
          query: 'dungeon',
          decodeCondition: {
            filter: { gameId: api.GameId.WorldOfWarcraftBurningCrusade },
          },
        }),
        region: new QueryHandler({
          query: 'region',
          decodeCondition: {
            filter: { gameId: api.GameId.WorldOfWarcraftBurningCrusade },
          },
        }),
        ilvl: new QueryHandler({
          query: 'ilvl',
          decodeType: Type.Number,
          decodeCondition: {
            filter: { gameId: api.GameId.WorldOfWarcraftBurningCrusade },
          },
        }),
      },
      wow: {
        region: new QueryHandler({
          query: 'region',
          aliases: aliases.wowRegion,
          decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
        }),
        ilvl: new QueryHandler({
          query: 'ilvl',
          decodeType: Type.Number,
          decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
        }),
        mythicPlusKey: new QueryHandler({
          query: 'mp_key',
          decodeType: Type.Number,
          decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
        }),
        mythicPlusRating: new QueryHandler({
          query: 'mp_rating',
          decodeType: Type.Number,
          decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
        }),
        faction: new QueryHandler({
          query: 'faction',
          aliases: aliases.wowFaction,
          decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
        }),
        raid: new QueryHandler({
          query: 'raid',
          decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
        }),
        raidDifficulty: new QueryHandler({
          query: 'raid_diff',
          decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
        }),
        dungeon: new QueryHandler({
          query: 'dungeon',
          decodeCondition: { filter: { gameId: api.GameId.WorldOfWarcraft } },
        }),
      },
    },
  },
);

export const DEFAULT_VARS = GROUPS_FEED_QUERY_CODER.decode('');

export const QUERY_KEY_GAME_SLUG =
  GROUPS_FEED_QUERY_CODER.handlers.filter?.gameId?.query || '';
export const QUERY_KEY_GAME_MODE =
  GROUPS_FEED_QUERY_CODER.handlers.filter?.gameMode?.query || '';
export const QUERY_KEY_OFFSET =
  GROUPS_FEED_QUERY_CODER.handlers.iteratorOptions?.offset?.query || 'offset';

// Redirect from `?game=wow&mode=mythic-plus` to `/wow-mplus`
export function encodeVarsAndReplaceGameSlug(
  variables: api.SearchEventsQueryVariables,
  pathname = '/',
): UrlObject {
  const query = GROUPS_FEED_QUERY_CODER.encode(variables);

  if (variables.filter.gameId) {
    const slug = getGameSlugPath(variables.filter.gameId, variables.filter.gameMode);

    if (!slug.path) {
      return { pathname: pathname, search: query };
    }

    // q.delete(QUERY_KEY_GAME_SLUG);
    // if (slug.deleteModeQuery) {
    //   q.delete(QUERY_KEY_GAME_MODE);
    // }

    return { pathname: pathname + slug.path, search: query };
  }

  return { pathname, search: query };
}

export const SESSION_INDEX_QUERY_KEY = (gameId: api.Maybe<api.GameId>) =>
  `lfg/query-index/${gameId}`;
