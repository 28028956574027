import { api } from 'api';
import { GROUPS_FEED_QUERY_CODER } from 'containers/Page[Game]GroupsFeed/query';

import { GAME_SLUGS } from './location';
import { routePlayersCoder } from './url/pagePlayers';

import type { SupportedGameId } from 'api/types';
import type { UrlObject } from 'url';

export const links = {
  home: { pathname: `/` },
  groups(gameId: SupportedGameId, params?: api.SearchEventsFilter): UrlObject {
    const slug = GAME_SLUGS[gameId];

    if (params) {
      params.gameId = gameId;
    }

    return {
      pathname: `/${slug}`,
      search: !params
        ? undefined
        : GROUPS_FEED_QUERY_CODER.encode({
            filter: params,
            iteratorOptions: { limit: 10, offset: 0 },
          }),
    };
  },
  event(owner: Pick<api.User, 'username'>, eventId: string): UrlObject {
    return { pathname: `/${owner.username}/events/${eventId}` };
  },
  eventChat(owner: Pick<api.User, 'username'>, eventId: string): UrlObject {
    return { pathname: `/${owner.username}/events/${eventId}/chat` };
  },
  /** @deprecated */
  groupById(groupId: string): UrlObject {
    return { pathname: `/event/${groupId}` };
  },
  organization: {
    home(alias: string): UrlObject {
      return { pathname: `/${alias}` };
    },
    roster(alias: string): UrlObject {
      return { pathname: `/${alias}/roster` };
    },
    members(alias: string): UrlObject {
      return { pathname: `/${alias}/members` };
    },
    wiki(alias: string): UrlObject {
      return { pathname: `/${alias}/links` };
    },
    followers(alias: string): UrlObject {
      return { pathname: `/${alias}/followers` };
    },
  },
  user: {
    home(username: string): UrlObject {
      return { pathname: `/${username}` };
    },
    links(username: string): UrlObject {
      return { pathname: `/${username}/links` };
    },
    history(username: string): UrlObject {
      return { pathname: `/${username}/history` };
    },
    following(username: string): UrlObject {
      return { pathname: `/${username}/following` };
    },
    followers(username: string): UrlObject {
      return { pathname: `/${username}/followers` };
    },
    visitors(username: string): UrlObject {
      return { pathname: `/${username}/visitors` };
    },
    settings(username: string): UrlObject {
      return { pathname: `/${username}/settings` };
    },
    game(username: string, gameId: string): UrlObject {
      return { pathname: `/${username}?v=${gameId}` };
    },
    suggestions(username: string): UrlObject {
      return { pathname: `/${username}/suggestions` };
    },
    referal(gameId: SupportedGameId, referralCode: string): UrlObject {
      const slug = GAME_SLUGS[gameId];

      return { pathname: `/${slug}`, query: { referralCode } };
    },
  },
  myGroups(): UrlObject {
    return { pathname: '/my' };
  },
  notifications(): UrlObject {
    return { pathname: '/notifications' };
  },
  players(gameId: SupportedGameId, params?: api.PlayersFilter): UrlObject {
    const slug = GAME_SLUGS[gameId];

    if (params) {
      params.gameId = gameId;
    }

    return {
      pathname: `/${slug}/players`,
      search: !params
        ? undefined
        : routePlayersCoder.encode({
            filter: params,
            iteratorOptions: { limit: 10, offset: 0 },
          }),
    };
  },
  landings: {
    about(): UrlObject {
      return { pathname: '/about' };
    },
    welcome(): UrlObject {
      return { pathname: '/welcome' };
    },
    termsOfService(): UrlObject {
      return { pathname: '/tos' };
    },
    privacyPolicy(): UrlObject {
      return { pathname: '/privacy' };
    },
  },
  pubgMobile: {
    leaderboards(): UrlObject {
      return { pathname: '/pubg-mobile/top' };
    },
  },
  modals: {
    onboarding(): UrlObject {
      return { pathname: '/onboarding' };
    },
    eventSettings(): UrlObject {
      return { pathname: '/event-settings' };
    },
  },
};

export function getCurrentLocationGame(
  pathname: string,
): SupportedGameId | undefined {
  const parts = pathname.split('/');
  // first part is always ""
  if (parts.length <= 1) {
    return undefined;
  }

  const candidate = parts[1];

  for (const gameIdStr in GAME_SLUGS) {
    const gameId = gameIdStr as SupportedGameId;

    if (GAME_SLUGS[gameId] === candidate) {
      return gameId;
    }
  }

  return undefined;
}
